<template>
  <div id="app" class="app-body">
    <div class="spacer"></div>
    <div class="columns is-vcentered">
      <div class="column is-5">
        <p class="is-subtitle is-secondary is-bold">Feedback</p>

        <div class="mt-5">
          <form
            v-if="!contactFormSubmitted"
            @submit.prevent="submitContactForm"
            id="form"
          >
            <p v-if="fasle" class="is-secondary is-bold">Full Name</p>
            <div v-if="fasle" class="my-5">
              <b-input
                v-model="fullName"
                required
                :disabled="autofill"
              ></b-input>
            </div>
            <p v-if="fasle" class="is-secondary is-bold">Email</p>
            <div v-if="fasle" class="my-5">
              <b-input
                v-model="email"
                type="email"
                required
                :disabled="autofill"
              ></b-input>
            </div>
            <p v-if="fasle" class="is-secondary is-bold">Message</p>
            <div class="my-5">
              <b-input v-model="message" type="textarea" required></b-input>
            </div>

            <div class="my-5 pt-5">
              <button form="form" class="button is-info" type="submit">
                Submit Feedback
              </button>
            </div>
          </form>

          <div v-if="contactFormSubmitted">
            Thank you for your feedback, it helps us make Brandeeq better.
          </div>
        </div>
      </div>
      <div class="column"></div>
      <div class="column is-4 has-text-centered">
        <img src="@/assets/img/misc/contact_us_illustration.svg" width="90%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feedback",
  data() {
    return {
      fullName: "",
      email: "",
      message: "",
      contactFormSubmitted: false,
      autofill: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  created() {
    if (this.currentUser) {
      this.email = this.currentUser.email;
      this.fullName = this.currentUser.fullName;
      this.autofill = true;
    }
  },
  methods: {
    async submitContactForm() {
      const submitted = await this.$store.dispatch("submitContactForm", {
        fullName: this.fullName,
        email: this.email,
        message: this.message,
      });
      if (submitted) {
        this.contactFormSubmitted = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
